.dataTableBox {
  :global(.sc-dIouRR) {
    padding: 0px;
    margin-bottom: 12px;
    justify-content: space-between;

    .tableFilterIcons {
      a {
        color: var(--AdminMain);
        margin-left: 10px;
        font-size: 19px;
      }
    }

    .searchBox {
      position: relative;

      .searchIcon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translate(-0%, -50%);
        color: #707070;
      }

      :global(.form-control) {
        width: 250px;
        padding-left: 35px;
        height: 40px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .action {
    width: 100%;
    font-size: 17px;
    text-align: center;

    .more {
      cursor: pointer;
    }

    :global(.dropdown-toggle) {
      background-color: #fff !important;
      border: none !important;
      color: #000 !important;
      padding: 0px;
      font-size: 18px;

      &::after {
        display: none;
      }

      &:focus {
        background-color: #fff;
        color: #000;
        box-shadow: none !important;
        outline: none;
      }
    }
  }

  :global(.eUeqdG) {
    font-weight: 600;
  }

  :global(.gelpCx) {
    font-family: "Inter", sans-serif !important;

    :global(.bnAwAJ) {
      font-size: 14px;
    }

    :global(.gJhVev) {
      font-size: 14px;
    }
  }

  :global(.btnStatus) {
    background: #a0abbb;
    border: none;
    color: #fff;
    padding: 6px 10px;
    min-width: 70px;
    display: inline-block;
    border-radius: 4px;
  }

  .dataTablBtns {
    .dataTablBtnsLeft {
      label {
        font-weight: 500;
        color: #64748b;
      }
    }

    .btnT {
      background: var(--AdminMain);
      color: #fff;
      padding: 10px 18px;
      font-weight: 400;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
  .dataTableBox {
    :global(.sc-dIouRR) {
      .searchBox {
        :global(.form-control) {
          width: 100%;
        }
      }
    }

    .dataTablBtns {
      .dataTablBtnsLeft {
        order: 1;
        margin-bottom: 10px;
      }

      .btnT {
        margin-bottom: 10px;
        padding: 5px 8px;
        font-size: 14px;
      }

       
    }
  }
  
  :global(.gelpCx) {
    position: relative !important;
    width: 100% !important;
    border-radius: inherit !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    min-height: 0 !important;
}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  :global(.gelpCx) {
    position: relative !important;
    width: 100% !important;
    border-radius: inherit !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    min-height: 0 !important;
}
}