.sideCard {
  background-color: #fff;
  box-shadow: 0 8px 16px 0px #e5e5e5;
  border-radius: 3px;
  margin-bottom: 30px;

  h5 {
    margin: 0px;
    padding: 15px 15px;
    font-size: 18px;
    color: #0D0F11;
  }

  hr {
    margin: 10px 0px 0px 0px;
  }

  .childUl {
    padding-right: 0px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0px 15px 15px 15px;

    li {
      position: relative;
      padding-right: 25px;
      font-size: 14px;
      margin-bottom: 13px;
      padding-left: 20px;
      line-height: 17px;

      .icon {
        position: absolute;
        top: 3px;
        left: 0;
        color: #7c7ef3;
        font-size: 15px;
      }

      .closeAction {
        position: absolute;
        right: 0px;
        top: 4px;
        cursor: pointer;
        padding: 0px;
      }
      p {
        margin: 0;
        font-style: italic;
        font-size: 14px;
        word-break: break-all;
      }
      .sideCardProfile {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .logoIcon {
          width: 35px;
          height: 35px;
          border-radius: 50px;
          object-fit: cover;
          margin-right: 6px;
        }
      }
    }
    .newResource {
      text-align: center;
      text-decoration: none;
      font-size: 14px;
      display: block;
      font-weight: 500;
      color: var(--AdminMain);
      padding: 10px 0px;
    }
  }
}

.uploadPhotoBox {
  text-align: center;

  .logoIcon {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    object-fit: cover;
    border: 1px solid #e4e4e4;
    padding: 5px;
  }
  .uploadPhoto {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    color: #000;
    padding: 5px 10px;
    width: 100%;
    margin: 10px 0px;
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &:focus {
      box-shadow: none;
    }

    input[type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  .reMove {
    background-color: transparent;
    border: none;
    padding: 0;
    color: var(--AdminMain);
  }
}

.fileUpload {
  text-align: center;
  border-style: dashed;
  border-width: 2px;
  border-radius: 5px;
  border-color: var(--AdminMain2);
  padding: 30px 20px;

  p {
    margin: 15px 0px 15px 0px;
  }
  span {
    color: var(--AdminMain);
  }
  .uploadFile {
    position: relative;
    text-align: center;
    padding: 10px 15px;
    border: 1px solid var(--AdminMain);
    border-radius: 5px;
    color: var(--AdminMain);
    width: 240px;
    margin: 0 auto;
    margin-top: 15px;

    input[type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    svg {
      margin-left: 5px;
      font-size: 14px;
      position: relative;
      top: -2px;

      path {
        stroke: var(--AdminMain);
      }
    }
  }
}
.modalCts {
  :global(.modal-dialog) {
    :global(.modal-content) {
      border: none;
      border-radius: 8px;
      background-color: #f7f8f9;

      :global(.modal-header) {
        border: none;
        padding: 40px 30px 10px 20px;
      }
      :global(.modal-body) {
        padding: 30px 30px;

        .modalTitle {
          h5 {
            margin: 0;
          }

          .previewBtn {
            background: var(--AdminMain);
            color: #fff;
            padding: 8px 15px;
            border-radius: 8px;
          }
        }
        .formBoxRow {
          background: #ffffff;
          margin: 20px 0px 0px 0px;
          border-radius: 10px;
          box-shadow: 0 5px 16px 0px #e4e4e4;
          padding: 20px 10px;

          h5  {
            color: #0D0F11;
          }

          .formBox {
            :global(.form-label) {
              font-size: 18px;
              font-weight: 500;
              color: #0D0F11;
            }

            :global(.form-select) {
              padding: 10px 15px;
              border: 1px solid #cacaca;

              &:focus {
                box-shadow: none;
                border-color: var(--AdminMain2);
              }
            }

            :global(.form-control) {
              padding: 10px 15px;
              border: 1px solid #cacaca;

              &:focus {
                box-shadow: none;
                border-color: var(--AdminMain2);
              }
            }
          }
        }
      }
      :global(.modal-footer) {
        border: none;
        padding: 0px 30px 30px 30px;

        .savBtnAnother {
          background: var(--AdminMain);
          color: #fff;
          border-color: var(--AdminMain);
          min-width: 150px;
          text-align: center;
          padding: 10px 20px;
          border-radius: 8px;
          font-weight: 500;
        }
        .savBtn {
          background: #fff;
          color: var(--AdminMain);
          border-color: var(--AdminMain);
          min-width: 150px;
          text-align: center;
          padding: 10px 20px;
          border-radius: 8px;
          font-weight: 500;
        }
      }
    }
  }
}

// .modalWidth{
//     width: 500px;
// }
