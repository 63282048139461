// .dataTableBox {

//     .dataTablBtns {

//         .btnT {
//             background:red;
//         }
//     }
// }
:global(.jMmClT){
    cursor: pointer;
}