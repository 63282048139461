.changePassword{
    .nameCoach{
        display: flex;
        align-items: center;
        .coachImg{
            margin-right: 30px;
            img{
                width: 80px;
                height: 80px;
                border-radius: 50px;
                margin-right: 10px;
                border: 1px solid #D0D5DD;
                padding: 1px;
            }
            h4{
                font-size: 15px;
                line-height: 17px;
                font-weight: 600;
                margin-top: 14px;
                margin-bottom: 0px;
            }
            .emailColor{
                color: var(--ParticipantMain);
            }
        }
    }
    .meetingPoint{
        margin-bottom: 0px;
        margin-top: 30px;
        color: var(--ParticipantMain);
        font-size: 15px;
        font-weight: 500;
    }
}



/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    
    
}