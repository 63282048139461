:root {

    --LoginMain: #006182;
    
    --ParticipantMain: #006182;
    --ParticipantMain2: #009CE0;
    --ParticipantHover: #9FA2B4;

    --AdminMain: #4338CA;
    --AdminMain2: #7C7EF3;
    --AdminHover: #9FA2B4;

    --CoachMain: #126D4D;
    --CoachMain2: #48BC93;
    --CoachHover: #9FA2B4;
}
