@import "../../../variables.module.scss"; 


.triageSection {
    min-height: 100vh;
     font-family: 'Mulish', sans-serif !important;
    background-color:#F7F8F9 !important;
    padding: 50px 0px;

    .centerBox {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
    }
    .cotainerBox {
        height:100%;
    }
    .loginInSide {
        position: relative;
        height: 100%;
    }

    .logoTitle {
        text-align:center;

        .logoIcon {
            height:70px;
            margin-bottom: 30px;
        }

        h2 {
            font-weight:600;
            font-size: 34px;
            margin-bottom: 40px;
        }
    }
}

.cardBox {
    background: #fff;
    padding: 20px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 21px 2px #dedede;
    text-align: center;
    margin-top: 30px;

    h4 {
        text-align: center;
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    .cardImg {
        width: 140px;
        height: 140px;
        margin: 0 auto;
        object-fit: cover;
        border-radius: 50%;
    }
    .cardFt {
        display: flex;
        width:100%;
        align-items: center;
        margin-top: 20px;

        :global(.progress) {
            width:100%;
            border-radius: 50px;
            margin-right: 10px;

            
            :global(.progress-bar) {
                background-color: #29CC97 !important;
            }

        }

        p{
            text-align: left;
            font-weight: 400;
            font-size: 14px;
            color: #126D4D;
            margin-bottom: 0px;
        }
        img{
            width: 18px;
            height: 18px;
            margin-right: 20px;
        }
    }
}

.mainFooter {
    background-color: var(--LoginMain);
    padding: 40px 0px;

    .ftBox {
        color:#fff;

        p {
            margin:0;
            margin-top: 30px;
        }

        h5 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        ul {
            list-style:none;
            margin:0;
            padding:0;

            li {

                a {
                    padding: 0;
                    color: #fff;
                    line-height: 35px;
                    text-decoration: underline;
                    font-size: 14px;
                }
            }
        }
    }
    .ftBoxRight {
        padding-left: 100px;
    }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

    .triageSection {
        height: 100%;
    
        .centerBox {
           position: relative;
           top: 0%;
           left: 0%;
           transform: translate(-0%, -0%);
        }
    
        .loginLeft {
            padding:50px 30px 0px 30px;
    
            .logoIcon {
                height: 50px;
            }
        }
    
        .loginRight {
            padding: 50px 30px;
    
            .form {
                padding:30px 30px;

                .submitBtnRow {
                    display: flex;
                    align-items: center;
    
                    .submitBtn {
                        min-width: 100px;
                    }
                }
            }
        }
    }

    .mainFooter {
        padding: 20px 0px 40px 0px;

        .mainFooterRow {
            
            .copyright {
                -webkit-order: 2;
            }
        }
    
        .ftBox {
            text-align: center;
            padding-top: 40px;
    
            p {
                margin-top: 15px;
            }
    
        }
        .ftBoxRight {
            padding: 0px 30px;
        }
    }
    

}


@media only screen and (min-width: 768px) and (max-width: 991px) {

    .triageSection {
       
        .loginLeft {
    
            .logoIcon {
                height: 60px;
                margin-bottom: 30px;
            }
        }
    
        .loginRight {
            padding-left: 15px;
    
        }
    }


    .mainFooter {
       
        .ftBoxRight {
            padding-left: 20px;
        }
    }
    
}