@import "../../variables.module.scss";


.loginSection {
    height: 100vh;
    font-family: 'Mulish', sans-serif !important;
    background-color: #F7F8F9 !important;

    .centerBox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .cotainerBox {
        height: 100%;
    }

    .loginInSide {
        position: relative;
        height: 100%;
    }

    .loginLeft {
        padding-right: 50px;

        .logoIcon {
            height: 90px;
            margin-bottom: 30px;
        }

        h5 {
            font-size: 24px;
            font-weight: 400;
            line-height: 35px;
            margin: 0;
        }
    }

    .loginRight {
        padding-left: 50px;

        .form {
            background-color: #fff;
            padding: 40px 40px;
            border-radius: 8px;
            box-shadow: 0 2px 21px 2px #dedede;

            h2 {
                margin-bottom: 20px;
                font-size: 26px;
                font-weight: 500;
            }

            .formBox {
                margin-bottom: 20px;
                position: relative;


                .formClass {
                    height: 50px;
                    border-radius: 8px;
                    border-color: #d2d2d2;

                    &:focus {
                        border-color: var(--LoginMain);
                        box-shadow: none;
                    }
                }

                .passwordAction {
                    font-size: 22px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: #5e5c5c;
                    cursor: pointer;
                }
            }

            .submitBtnRow {
                display: flex;
                align-items: center;

                .submitBtn {
                    background-color: #D0D5DD;
                    color: #64748B;
                    font-weight: 600;
                    border-color: #D0D5DD;
                    min-width: 200px;
                    padding: 10px 10px;
                    text-decoration: none;
                    text-align: center;
                    border-radius: 5px;
                }

                .forogotPassword {
                    padding: 0px;
                    font-weight: 400;
                    width: 100%;
                    text-decoration: none;
                }
            }

        }
    }
}

.mainFooter {
    background-color: var(--LoginMain);
    padding: 40px 0px;

    .ftBox {
        color: #fff;

        p {
            margin: 0;
            margin-top: 30px;
        }

        h5 {
            font-size: 20px;
            margin-bottom: 10px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {

                a {
                    padding: 0;
                    color: #fff;
                    line-height: 35px;
                    text-decoration: underline;
                    font-size: 14px;
                }
            }
        }
    }

    .ftBoxRight {
        padding-left: 100px;
    }
}


/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

    .loginSection {
        height: 100%;

        .centerBox {
            position: relative;
            top: 0%;
            left: 0%;
            transform: translate(-0%, -0%);
        }

        .loginLeft {
            padding: 50px 30px 0px 30px;

            .logoIcon {
                height: 50px;
            }
        }

        .loginRight {
            padding: 50px 30px;

            .form {
                padding: 30px 30px;

                .submitBtnRow {
                    display: flex;
                    align-items: center;

                    .submitBtn {
                        min-width: 100px;
                    }
                }
            }
        }
    }

    .mainFooter {
        padding: 20px 0px 40px 0px;

        .mainFooterRow {

            .copyright {
                -webkit-order: 2;
            }
        }

        .ftBox {
            text-align: center;
            padding-top: 40px;

            p {
                margin-top: 15px;
            }

        }

        .ftBoxRight {
            padding: 0px 30px;
        }
    }


}


@media only screen and (min-width: 768px) and (max-width: 991px) {

    .loginSection {

        .loginLeft {

            .logoIcon {
                height: 60px;
                margin-bottom: 30px;
            }
        }

        .loginRight {
            padding-left: 15px;

        }
    }


    .mainFooter {

        .ftBoxRight {
            padding-left: 20px;
        }
    }

}