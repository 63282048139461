
.dataTablBtns {

    .btnT {
        background: var(--AdminMain);
        color: #fff !important;
        padding: 10px 18px;
        font-weight: 400;
        box-shadow: 0 8px 16px 0px #e5e5e5;

        &:focus {
            box-shadow: none;
        }
    }
    .btnW {
        background: #fff;
        color: var(--AdminMain) !important;
        padding: 10px 18px;
        font-weight: 400;
        margin-right: 20px;
        box-shadow: 0 8px 16px 0px #e5e5e5;

        &:focus {
            box-shadow: none;
        }
    }
}

.manageRow {
    margin-top:20px !important;

    .sideBody {

        .formBox {
            text-align: left;
            
            :global(.form-control){
                border-radius: 0.25rem !important;
            }
            :global(.btn){
                border: 1px solid var(--AdminMain);
                color:var(--AdminMain);
                border-radius: 5px !important;
                margin-left: 15px !important;

                &.active {
                    background: var(--AdminMain);
                    border: 1px solid var(--AdminMain);
                    color:#fff;
                }
            }
        }
    }
}

.dataTableBox2 {

    :global(.sc-dIouRR) {
        display:none;
    }
}

.simpleTable {

    thead {

        tr {

            th {
                font-size: 14px;
            }
        }
    }
    tbody {
        border-top:none !important;

        tr {

            td {
                font-size: 14px;
            }
        }

        button {

            &:global(.btn) {
                background-color: #A0ABBB;
                font-size: 14px;
                padding: 2px 10px;
                color:#fff;
                min-width: 90px;

                &.active {
                    background:#34D399;
                }
            }
        }
        
        .actionIcon {

            span {
                font-size: 22px;
                color: #B8C0CC;
                margin-left: 15px;
            }
        }
        
    }
}

.searchBox {
    position: relative;

    .searchIcon {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translate(-0%, -50%);
        color: #707070;
    }
    :global(.form-control) {
        width: 100%;
        padding-left: 35px;
        height: 40px;

        &:focus {
            box-shadow: none;
        }
    }
}

.sideList {

    :global(.card-body) {
        padding:0px 0px 30px 0px !important;

        :global(.card-text) {
            padding:30px 30px 0px 30px;
        }

        ul {
            list-style:none;
            margin:0;
            padding:0;

            li {
                border-bottom: 1px solid #e7e7e7;
                padding: 15px 30px;
                cursor: pointer;

                h5 {
                    font-size: 18px;
                    margin-bottom: 4px; 
                }
                p {
                    color:#A0ABBB;
                    margin:0px;
                }

                &:hover, &.active {
                    background-color: #48BC9333;
                }
                &:last-child {
                    border:none;
                }
            }
        }
    }
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {
    .dataTablBtns {

        .btnT {
            width: 100%;
        }
        .btnW {
            width: 100%;
            margin-bottom:10px;
        }
    }
    .manageRow {
        margin-top:20px !important;
    
        .sideBody {
    
            .formBox {
                text-align: left;

                :global(.input-group){
                   display: block;
                }
                
                :global(.form-control){
                    width: 100%;
                }
                :global(.btn){
                    width: 100%;
                    margin-left:0px !important;
                    margin-top: 10px;
                }
            }
        }
    }
   
}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
   
}